
import { Component, Vue } from 'vue-property-decorator';
import { Form, Spin } from 'ant-design-vue';
import RecurrenceRule from './RecurrenceRule.vue';
import { RouteNames } from '@/enums/routes/RouteNames';
import Resource from '@/models/Resource';
import ResourceRepository from '@/repositories/ResourceRepository';

@Component({
    name: 'CalendarResourceEdit',
    components: {
        Form,
        Spin,
        FormItem: Form.Item,
        RecurrenceRule,
    },
})
export default class CalendarResourceEdit extends Vue {
    private id: string | null = null;
    private itemData: Resource | null = {} as Resource;
    private isLoading: boolean = false;

    private async getCalendarResourceData() {
        this.isLoading = true;

        try {
            if (!this.id) {
                return;
            }

            await Resource.getById(this.id);
            this.itemData = ResourceRepository.getById(this.id);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }
    }

    private async addCalendarResource() {
        let resp;
        try {
            if (!this.id && this.itemData) {
                resp = await Resource.createNew(this.itemData);
            }
            if (resp) {
                this.$notification.success({
                    message: this.$t('Promjene uspješne!') as string,
                    description: '',
                });
                await this.$router.push({
                    name: RouteNames.schedulesResources,
                    params: { dynamic: 'CalendarResources' },
                });
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            await this.$router.push({
                name: RouteNames.schedulesResources,
                params: { dynamic: 'CalendarResources' },
            });
        }
    }

    private async updateCalendarResource() {
        let resp;
        try {
            if (this.id && this.itemData) {
                resp = await Resource.updateExisting(this.id, this.itemData);
                if (resp && resp.status === 200) {
                    this.$notification.success({
                        message: this.$t('Promjene uspješne!') as string,
                        description: '',
                    });
                    await this.$router.push({
                        name: RouteNames.schedulesResources,
                        params: { dynamic: 'CalendarResources' },
                    });
                }
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            await this.$router.push({
                name: RouteNames.schedulesResources,
                params: { dynamic: 'CalendarResources' },
            });
        }
    }

    private async mounted() {
        this.id = this.$route.params.id === 'nov' ? null : this.$route.params.id;
        if (this.id) {
            this.getCalendarResourceData();
        }
    }
}
